import { Grd, Bx, Btn, IconBtn, Typo, useBreakpoints, Ctn } from '@curry-group/mui-curcuma';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer, useMapEvents, WMSTileLayer } from 'react-leaflet';
import { defaultCoords } from '../../../parts';

const mapTenant = process.env.REACT_APP_MAP_TENANT || 'pflegeplatzboerse-7c4d6e49';

export const LocatorMarker: React.FC<{ latLon: [number, number]; setLatLon?: (value: [number, number]) => void }> = ({ latLon, setLatLon }) => {
  const map = useMapEvents({
    dblclick: e => {
      const coords: [number, number] = [e.latlng.lat, e.latlng.lng];
      setLatLon?.(coords);
    }
  });
  useEffect(() => {
    map.flyTo(latLon, map.getZoom());
  }, [map, latLon]);

  return <Marker position={latLon} />;
};

export const LocatorForm: React.FC<{
  onClose?: () => void;
  onAccept?: (value: [number, number]) => void;
  initialLatLon: [number, number];
}> = ({ onClose, onAccept, initialLatLon }) => {
  const [streetAddress, setStreetAddress] = useState('');
  const [addressLevel2, setAddressLevel2] = useState('');
  const [latLon, setLatLon] = useState<[number, number]>(initialLatLon ?? defaultCoords);
  const [error, setError] = useState('');
  const breakpoints = useBreakpoints();
  const browserLocationAvailable = 'geolocation' in navigator;
  const locationByBrowser = () => {
    if (browserLocationAvailable) {
      setError('');
      navigator.geolocation.getCurrentPosition(p => {
        setLatLon([p.coords.latitude, p.coords.longitude]);
      });
    }
  };
  const locationByAddress = async () => {
    setError('');
    const response = await fetch('/uwao-api/care-erlangen/geo/search?q=' + addressLevel2 + (streetAddress ? ', ' + streetAddress : ''));
    if (response.ok) {
      const result = await response.json();
      if (!result.coords) {
        setError('Adresse konnte nicht gefunden werden.');
      } else {
        setLatLon(result.coords);
      }
    }
  };

  return (
    <Ctn disableGutters={false} maxWidth="md">
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          locationByAddress();
        }}
      >
        <Bx pt={4} pb={4}>
          <Grd container spacing={2}>
            <Grd item xs={12}>
              <Bx display="flex" justifyContent="space-between">
                <Typo variant="h3">Standort</Typo>
                <IconBtn size="small" onClick={onClose} aria-label="Schließen">
                  <FontAwesomeIcon icon={faTimes} />
                </IconBtn>
              </Bx>
            </Grd>
            <Grd item xs={6}>
              <TextField
                id="address-level2"
                label="Ort"
                value={addressLevel2}
                name="address-level2"
                autoComplete="address-level2"
                onChange={e => {
                  setAddressLevel2(e.target.value);
                }}
                fullWidth
              />
            </Grd>
            <Grd item xs={6}>
              <TextField
                id="street-address"
                label="Adresse"
                value={streetAddress}
                name="street-address"
                autoComplete="street-address"
                onChange={e => {
                  setStreetAddress(e.target.value);
                }}
                fullWidth
              />
            </Grd>
            <Grd item xs={12}>
              <Bx display="flex">
                <Btn color="primary" variant="contained" type="submit" fullWidth>
                  Adresse finden
                </Btn>
                <Bx p={1} />
                <Btn disabled={!browserLocationAvailable} color="primary" variant="contained" fullWidth onClick={locationByBrowser}>
                  Standort ermitteln
                </Btn>
              </Bx>
            </Grd>
            <Grd item xs={12}>
              <Typo color="error">{error}</Typo>
            </Grd>
            <Grd item xs={12}>
              {breakpoints.smDown && (
                <MapContainer center={latLon} zoom={14} doubleClickZoom={false} style={{ height: 240 }}>
                  <WMSTileLayer
                    url="https://sgx.geodatenzentrum.de/wms_basemapde?service=wms&request=GetMap&"
                    layers="de_basemapde_web_raster_farbe"
                    format="image/png"
                    styles="default"
                    minZoom={1}
                    maxZoom={25}
                    version="1.3.0"
                    attribution='© GeoBasis-DE / <a href="https://www.bkg.bund.de/" target="_blank">BKG 2024</a> <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0</a>'
                    params={{
                      transparent: true,
                      layers: 'de_basemapde_web_raster_farbe',
                      format: 'image/png',
                      styles: 'default',
                      version: '1.3.0'
                    }}
                  />
                  <LocatorMarker latLon={latLon} setLatLon={setLatLon} />
                </MapContainer>
              )}
              {!breakpoints.smDown && (
                <MapContainer center={latLon} zoom={14} doubleClickZoom={false} style={{ height: 380 }}>
                  <WMSTileLayer
                    url="https://sgx.geodatenzentrum.de/wms_basemapde?service=wms&request=GetMap&"
                    layers="de_basemapde_web_raster_farbe"
                    format="image/png"
                    styles="default"
                    minZoom={1}
                    maxZoom={25}
                    version="1.3.0"
                    attribution='© GeoBasis-DE / <a href="https://www.bkg.bund.de/" target="_blank">BKG 2024</a> <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0</a>'
                    params={{
                      transparent: true,
                      layers: 'de_basemapde_web_raster_farbe',
                      format: 'image/png',
                      styles: 'default',
                      version: '1.3.0'
                    }}
                  />
                  <LocatorMarker latLon={latLon} setLatLon={setLatLon} />
                </MapContainer>
              )}
            </Grd>
            <Grd item xs={12}>
              <Btn
                disabled={!latLon || defaultCoords === latLon}
                color="primary"
                variant="contained"
                onClick={() => {
                  onAccept?.(latLon);
                  onClose?.();
                }}
                fullWidth
              >
                Übernehmen
              </Btn>
            </Grd>
          </Grd>
        </Bx>
      </form>
    </Ctn>
  );
};
